import { FormProvider as RcFormProvider } from 'rc-field-form';
import omit from 'rc-util/lib/omit';
import * as React from 'react';
import { useContext, useMemo } from 'react';
export const FormContext = React.createContext({
    labelAlign: 'right',
    vertical: false,
    itemRef: (() => { }),
});
export const NoStyleItemContext = React.createContext(null);
export const FormProvider = props => {
    const providerProps = omit(props, ['prefixCls']);
    return React.createElement(RcFormProvider, Object.assign({}, providerProps));
};
export const FormItemPrefixContext = React.createContext({
    prefixCls: '',
});
export const FormItemInputContext = React.createContext({});
export const NoFormStyle = ({ children, status, override }) => {
    const formItemInputContext = useContext(FormItemInputContext);
    const newFormItemInputContext = useMemo(() => {
        const newContext = Object.assign({}, formItemInputContext);
        if (override) {
            delete newContext.isFormItemInput;
        }
        if (status) {
            delete newContext.status;
            delete newContext.hasFeedback;
            delete newContext.feedbackIcon;
        }
        return newContext;
    }, [status, override, formItemInputContext]);
    return (React.createElement(FormItemInputContext.Provider, { value: newFormItemInputContext }, children));
};
