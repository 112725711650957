import classNames from 'classnames';
import omit from 'rc-util/lib/omit';
import * as React from 'react';
import { ConfigContext } from '../config-provider';
import Element from './Element';
const SkeletonAvatar = (props) => {
    const { prefixCls: customizePrefixCls, className, active } = props;
    const { getPrefixCls } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('skeleton', customizePrefixCls);
    const otherProps = omit(props, ['prefixCls', 'className']);
    const cls = classNames(prefixCls, `${prefixCls}-element`, {
        [`${prefixCls}-active`]: active,
    }, className);
    return (React.createElement("div", { className: cls },
        React.createElement(Element, Object.assign({ prefixCls: `${prefixCls}-avatar` }, otherProps))));
};
SkeletonAvatar.defaultProps = {
    size: 'default',
    shape: 'circle',
};
export default SkeletonAvatar;
