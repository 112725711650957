var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import warning from '../_util/warning';
import Base from './Base';
const Link = (_a, ref) => {
    var { ellipsis, rel } = _a, restProps = __rest(_a, ["ellipsis", "rel"]);
    warning(typeof ellipsis !== 'object', 'Typography.Link', '`ellipsis` only supports boolean value.');
    const baseRef = React.useRef(null);
    React.useImperativeHandle(ref, () => baseRef.current);
    const mergedProps = Object.assign(Object.assign({}, restProps), { rel: rel === undefined && restProps.target === '_blank' ? 'noopener noreferrer' : rel });
    // https://github.com/ant-design/ant-design/issues/26622
    // @ts-ignore
    delete mergedProps.navigate;
    return React.createElement(Base, Object.assign({}, mergedProps, { ref: baseRef, ellipsis: !!ellipsis, component: "a" }));
};
export default React.forwardRef(Link);
