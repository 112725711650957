import CheckCircleFilled from '@ant-design/icons/CheckCircleFilled';
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';
import InfoCircleFilled from '@ant-design/icons/InfoCircleFilled';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import classNames from 'classnames';
import RCNotification from 'rc-notification';
import * as React from 'react';
import ConfigProvider, { globalConfig } from '../config-provider';
import createUseMessage from './hooks/useMessage';
let messageInstance;
let defaultDuration = 3;
let defaultTop;
let key = 1;
let localPrefixCls = '';
let transitionName = 'move-up';
let hasTransitionName = false;
let getContainer;
let maxCount;
let rtl = false;
export function getKeyThenIncreaseKey() {
    return key++;
}
function setMessageConfig(options) {
    if (options.top !== undefined) {
        defaultTop = options.top;
        messageInstance = null; // delete messageInstance for new defaultTop
    }
    if (options.duration !== undefined) {
        defaultDuration = options.duration;
    }
    if (options.prefixCls !== undefined) {
        localPrefixCls = options.prefixCls;
    }
    if (options.getContainer !== undefined) {
        getContainer = options.getContainer;
        messageInstance = null; // delete messageInstance for new getContainer
    }
    if (options.transitionName !== undefined) {
        transitionName = options.transitionName;
        messageInstance = null; // delete messageInstance for new transitionName
        hasTransitionName = true;
    }
    if (options.maxCount !== undefined) {
        maxCount = options.maxCount;
        messageInstance = null;
    }
    if (options.rtl !== undefined) {
        rtl = options.rtl;
    }
}
function getRCNotificationInstance(args, callback) {
    const { prefixCls: customizePrefixCls, getPopupContainer: getContextPopupContainer } = args;
    const { getPrefixCls, getRootPrefixCls, getIconPrefixCls } = globalConfig();
    const prefixCls = getPrefixCls('message', customizePrefixCls || localPrefixCls);
    const rootPrefixCls = getRootPrefixCls(args.rootPrefixCls, prefixCls);
    const iconPrefixCls = getIconPrefixCls();
    if (messageInstance) {
        callback({ prefixCls, rootPrefixCls, iconPrefixCls, instance: messageInstance });
        return;
    }
    const instanceConfig = {
        prefixCls,
        transitionName: hasTransitionName ? transitionName : `${rootPrefixCls}-${transitionName}`,
        style: { top: defaultTop },
        getContainer: getContainer || getContextPopupContainer,
        maxCount,
    };
    RCNotification.newInstance(instanceConfig, (instance) => {
        if (messageInstance) {
            callback({ prefixCls, rootPrefixCls, iconPrefixCls, instance: messageInstance });
            return;
        }
        messageInstance = instance;
        if (process.env.NODE_ENV === 'test') {
            messageInstance.config = instanceConfig;
        }
        callback({ prefixCls, rootPrefixCls, iconPrefixCls, instance });
    });
}
const typeToIcon = {
    info: InfoCircleFilled,
    success: CheckCircleFilled,
    error: CloseCircleFilled,
    warning: ExclamationCircleFilled,
    loading: LoadingOutlined,
};
export const typeList = Object.keys(typeToIcon);
function getRCNoticeProps(args, prefixCls, iconPrefixCls) {
    const duration = args.duration !== undefined ? args.duration : defaultDuration;
    const IconComponent = typeToIcon[args.type];
    const messageClass = classNames(`${prefixCls}-custom-content`, {
        [`${prefixCls}-${args.type}`]: args.type,
        [`${prefixCls}-rtl`]: rtl === true,
    });
    return {
        key: args.key,
        duration,
        style: args.style || {},
        className: args.className,
        content: (React.createElement(ConfigProvider, { iconPrefixCls: iconPrefixCls },
            React.createElement("div", { className: messageClass },
                args.icon || (IconComponent && React.createElement(IconComponent, null)),
                React.createElement("span", null, args.content)))),
        onClose: args.onClose,
        onClick: args.onClick,
    };
}
function notice(args) {
    const target = args.key || getKeyThenIncreaseKey();
    const closePromise = new Promise(resolve => {
        const callback = () => {
            if (typeof args.onClose === 'function') {
                args.onClose();
            }
            return resolve(true);
        };
        getRCNotificationInstance(args, ({ prefixCls, iconPrefixCls, instance }) => {
            instance.notice(getRCNoticeProps(Object.assign(Object.assign({}, args), { key: target, onClose: callback }), prefixCls, iconPrefixCls));
        });
    });
    const result = () => {
        if (messageInstance) {
            messageInstance.removeNotice(target);
        }
    };
    result.then = (filled, rejected) => closePromise.then(filled, rejected);
    result.promise = closePromise;
    return result;
}
function isArgsProps(content) {
    return (Object.prototype.toString.call(content) === '[object Object]' &&
        !!content.content);
}
const api = {
    open: notice,
    config: setMessageConfig,
    destroy(messageKey) {
        if (messageInstance) {
            if (messageKey) {
                const { removeNotice } = messageInstance;
                removeNotice(messageKey);
            }
            else {
                const { destroy } = messageInstance;
                destroy();
                messageInstance = null;
            }
        }
    },
};
export function attachTypeApi(originalApi, type) {
    originalApi[type] = (content, duration, onClose) => {
        if (isArgsProps(content)) {
            return originalApi.open(Object.assign(Object.assign({}, content), { type }));
        }
        if (typeof duration === 'function') {
            onClose = duration;
            duration = undefined;
        }
        return originalApi.open({ content, duration, type, onClose });
    };
}
typeList.forEach(type => attachTypeApi(api, type));
api.warn = api.warning;
api.useMessage = createUseMessage(getRCNotificationInstance, getRCNoticeProps);
/** @private test Only function. Not work on production */
export const getInstance = () => (process.env.NODE_ENV === 'test' ? messageInstance : null);
export default api;
