var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import EllipsisOutlined from '@ant-design/icons/EllipsisOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import classNames from 'classnames';
import RcTabs, { TabPane, TabPaneProps } from 'rc-tabs';
import * as React from 'react';
import { ConfigContext } from '../config-provider';
import SizeContext from '../config-provider/SizeContext';
import warning from '../_util/warning';
export { TabPaneProps };
function Tabs(_a) {
    var { type, className, size: propSize, onEdit, hideAdd, centered, addIcon } = _a, props = __rest(_a, ["type", "className", "size", "onEdit", "hideAdd", "centered", "addIcon"]);
    const { prefixCls: customizePrefixCls, moreIcon = React.createElement(EllipsisOutlined, null) } = props;
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('tabs', customizePrefixCls);
    let editable;
    if (type === 'editable-card') {
        editable = {
            onEdit: (editType, { key, event }) => {
                onEdit === null || onEdit === void 0 ? void 0 : onEdit(editType === 'add' ? event : key, editType);
            },
            removeIcon: React.createElement(CloseOutlined, null),
            addIcon: addIcon || React.createElement(PlusOutlined, null),
            showAdd: hideAdd !== true,
        };
    }
    const rootPrefixCls = getPrefixCls();
    warning(!('onPrevClick' in props) && !('onNextClick' in props), 'Tabs', '`onPrevClick` and `onNextClick` has been removed. Please use `onTabScroll` instead.');
    return (React.createElement(SizeContext.Consumer, null, contextSize => {
        const size = propSize !== undefined ? propSize : contextSize;
        return (React.createElement(RcTabs, Object.assign({ direction: direction, moreTransitionName: `${rootPrefixCls}-slide-up` }, props, { className: classNames({
                [`${prefixCls}-${size}`]: size,
                [`${prefixCls}-card`]: ['card', 'editable-card'].includes(type),
                [`${prefixCls}-editable-card`]: type === 'editable-card',
                [`${prefixCls}-centered`]: centered,
            }, className), editable: editable, moreIcon: moreIcon, prefixCls: prefixCls })));
    }));
}
Tabs.TabPane = TabPane;
export default Tabs;
