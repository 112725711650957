import * as React from 'react';
import Tooltip from '../../tooltip';
const EllipsisTooltip = ({ enabledEllipsis, isEllipsis, children, tooltipProps, }) => {
    if (!(tooltipProps === null || tooltipProps === void 0 ? void 0 : tooltipProps.title) || !enabledEllipsis) {
        return children;
    }
    return (React.createElement(Tooltip, Object.assign({ visible: isEllipsis ? undefined : false }, tooltipProps), children));
};
if (process.env.NODE_ENV !== 'production') {
    EllipsisTooltip.displayName = 'EllipsisTooltip';
}
export default EllipsisTooltip;
