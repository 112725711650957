var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import EyeOutlined from '@ant-design/icons/EyeOutlined';
import RcImage, { ImageProps } from 'rc-image';
import * as React from 'react';
import { useContext } from 'react';
import { ConfigContext } from '../config-provider';
import defaultLocale from '../locale/en_US';
import { getTransitionName } from '../_util/motion';
import PreviewGroup, { icons } from './PreviewGroup';
const Image = (_a) => {
    var { prefixCls: customizePrefixCls, preview } = _a, otherProps = __rest(_a, ["prefixCls", "preview"]);
    const { getPrefixCls, locale: contextLocale = defaultLocale, getPopupContainer: getContextPopupContainer, } = useContext(ConfigContext);
    const prefixCls = getPrefixCls('image', customizePrefixCls);
    const rootPrefixCls = getPrefixCls();
    const imageLocale = contextLocale.Image || defaultLocale.Image;
    const mergedPreview = React.useMemo(() => {
        if (preview === false) {
            return preview;
        }
        const _preview = typeof preview === 'object' ? preview : {};
        const { getContainer } = _preview, restPreviewProps = __rest(_preview, ["getContainer"]);
        return Object.assign(Object.assign({ mask: (React.createElement("div", { className: `${prefixCls}-mask-info` },
                React.createElement(EyeOutlined, null), imageLocale === null || imageLocale === void 0 ? void 0 :
                imageLocale.preview)), icons }, restPreviewProps), { getContainer: getContainer || getContextPopupContainer, transitionName: getTransitionName(rootPrefixCls, 'zoom', _preview.transitionName), maskTransitionName: getTransitionName(rootPrefixCls, 'fade', _preview.maskTransitionName) });
    }, [preview, imageLocale]);
    return React.createElement(RcImage, Object.assign({ prefixCls: prefixCls, preview: mergedPreview }, otherProps));
};
export { ImageProps };
Image.PreviewGroup = PreviewGroup;
export default Image;
