import classNames from 'classnames';
import * as React from 'react';
import { ConfigConsumer } from '../config-provider';
import AnchorContext from './context';
class AnchorLink extends React.Component {
    constructor() {
        super(...arguments);
        this.handleClick = (e) => {
            const { scrollTo, onClick } = this.context;
            const { href, title } = this.props;
            onClick === null || onClick === void 0 ? void 0 : onClick(e, { title, href });
            scrollTo(href);
        };
        this.renderAnchorLink = ({ getPrefixCls }) => {
            const { prefixCls: customizePrefixCls, href, title, children, className, target } = this.props;
            const prefixCls = getPrefixCls('anchor', customizePrefixCls);
            const active = this.context.activeLink === href;
            const wrapperClassName = classNames(`${prefixCls}-link`, {
                [`${prefixCls}-link-active`]: active,
            }, className);
            const titleClassName = classNames(`${prefixCls}-link-title`, {
                [`${prefixCls}-link-title-active`]: active,
            });
            return (React.createElement("div", { className: wrapperClassName },
                React.createElement("a", { className: titleClassName, href: href, title: typeof title === 'string' ? title : '', target: target, onClick: this.handleClick }, title),
                children));
        };
    }
    componentDidMount() {
        this.context.registerLink(this.props.href);
    }
    componentDidUpdate({ href: prevHref }) {
        const { href } = this.props;
        if (prevHref !== href) {
            this.context.unregisterLink(prevHref);
            this.context.registerLink(href);
        }
    }
    componentWillUnmount() {
        this.context.unregisterLink(this.props.href);
    }
    render() {
        return React.createElement(ConfigConsumer, null, this.renderAnchorLink);
    }
}
AnchorLink.defaultProps = {
    href: '#',
};
AnchorLink.contextType = AnchorContext;
export default AnchorLink;
