var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import * as React from 'react';
import { ConfigContext } from '../config-provider';
import LocaleReceiver from '../locale-provider/LocaleReceiver';
import DefaultEmptyImg from './empty';
import SimpleEmptyImg from './simple';
const defaultEmptyImg = React.createElement(DefaultEmptyImg, null);
const simpleEmptyImg = React.createElement(SimpleEmptyImg, null);
const Empty = (_a) => {
    var { className, prefixCls: customizePrefixCls, image = defaultEmptyImg, description, children, imageStyle } = _a, restProps = __rest(_a, ["className", "prefixCls", "image", "description", "children", "imageStyle"]);
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    return (React.createElement(LocaleReceiver, { componentName: "Empty" }, (locale) => {
        const prefixCls = getPrefixCls('empty', customizePrefixCls);
        const des = typeof description !== 'undefined' ? description : locale.description;
        const alt = typeof des === 'string' ? des : 'empty';
        let imageNode = null;
        if (typeof image === 'string') {
            imageNode = React.createElement("img", { alt: alt, src: image });
        }
        else {
            imageNode = image;
        }
        return (React.createElement("div", Object.assign({ className: classNames(prefixCls, {
                [`${prefixCls}-normal`]: image === simpleEmptyImg,
                [`${prefixCls}-rtl`]: direction === 'rtl',
            }, className) }, restProps),
            React.createElement("div", { className: `${prefixCls}-image`, style: imageStyle }, imageNode),
            des && React.createElement("div", { className: `${prefixCls}-description` }, des),
            children && React.createElement("div", { className: `${prefixCls}-footer` }, children)));
    }));
};
Empty.PRESENTED_IMAGE_DEFAULT = defaultEmptyImg;
Empty.PRESENTED_IMAGE_SIMPLE = simpleEmptyImg;
export default Empty;
