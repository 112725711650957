import raf from 'rc-util/lib/raf';
export function throttleByAnimationFrame(fn) {
    let requestId;
    const later = (args) => () => {
        requestId = null;
        fn(...args);
    };
    const throttled = (...args) => {
        if (requestId == null) {
            requestId = raf(later(args));
        }
    };
    throttled.cancel = () => {
        raf.cancel(requestId);
        requestId = null;
    };
    return throttled;
}
export function throttleByAnimationFrameDecorator() {
    return function throttle(target, key, descriptor) {
        const fn = descriptor.value;
        let definingProperty = false;
        return {
            configurable: true,
            get() {
                // In IE11 calling Object.defineProperty has a side-effect of evaluating the
                // getter for the property which is being replaced. This causes infinite
                // recursion and an "Out of stack space" error.
                // eslint-disable-next-line no-prototype-builtins
                if (definingProperty || this === target.prototype || this.hasOwnProperty(key)) {
                    /* istanbul ignore next */
                    return fn;
                }
                const boundFn = throttleByAnimationFrame(fn.bind(this));
                definingProperty = true;
                Object.defineProperty(this, key, {
                    value: boundFn,
                    configurable: true,
                    writable: true,
                });
                definingProperty = false;
                return boundFn;
            },
        };
    };
}
