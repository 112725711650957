import padEnd from 'lodash/padEnd';
import * as React from 'react';
const StatisticNumber = props => {
    const { value, formatter, precision, decimalSeparator, groupSeparator = '', prefixCls } = props;
    let valueNode;
    if (typeof formatter === 'function') {
        // Customize formatter
        valueNode = formatter(value);
    }
    else {
        // Internal formatter
        const val = String(value);
        const cells = val.match(/^(-?)(\d*)(\.(\d+))?$/);
        // Process if illegal number
        if (!cells || val === '-') {
            valueNode = val;
        }
        else {
            const negative = cells[1];
            let int = cells[2] || '0';
            let decimal = cells[4] || '';
            int = int.replace(/\B(?=(\d{3})+(?!\d))/g, groupSeparator);
            if (typeof precision === 'number') {
                decimal = padEnd(decimal, precision, '0').slice(0, precision > 0 ? precision : 0);
            }
            if (decimal) {
                decimal = `${decimalSeparator}${decimal}`;
            }
            valueNode = [
                React.createElement("span", { key: "int", className: `${prefixCls}-content-value-int` },
                    negative,
                    int),
                decimal && (React.createElement("span", { key: "decimal", className: `${prefixCls}-content-value-decimal` }, decimal)),
            ];
        }
    }
    return React.createElement("span", { className: `${prefixCls}-content-value` }, valueNode);
};
export default StatisticNumber;
