import { useEffect, useRef } from 'react';
import useForceUpdate from '../../_util/hooks/useForceUpdate';
import ResponsiveObserve from '../../_util/responsiveObserve';
function useBreakpoint(refreshOnChange = true) {
    const screensRef = useRef({});
    const forceUpdate = useForceUpdate();
    useEffect(() => {
        const token = ResponsiveObserve.subscribe(supportScreens => {
            screensRef.current = supportScreens;
            if (refreshOnChange) {
                forceUpdate();
            }
        });
        return () => ResponsiveObserve.unsubscribe(token);
    }, []);
    return screensRef.current;
}
export default useBreakpoint;
