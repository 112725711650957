import classNames from 'classnames';
import toArray from 'rc-util/lib/Children/toArray';
import * as React from 'react';
import { ConfigContext } from '../config-provider';
import Popover from '../popover';
import { cloneElement } from '../_util/reactNode';
import Avatar from './avatar';
import { SizeContextProvider } from './SizeContext';
const Group = props => {
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const { prefixCls: customizePrefixCls, className = '', maxCount, maxStyle, size } = props;
    const prefixCls = getPrefixCls('avatar-group', customizePrefixCls);
    const cls = classNames(prefixCls, {
        [`${prefixCls}-rtl`]: direction === 'rtl',
    }, className);
    const { children, maxPopoverPlacement = 'top', maxPopoverTrigger = 'hover' } = props;
    const childrenWithProps = toArray(children).map((child, index) => cloneElement(child, {
        key: `avatar-key-${index}`,
    }));
    const numOfChildren = childrenWithProps.length;
    if (maxCount && maxCount < numOfChildren) {
        const childrenShow = childrenWithProps.slice(0, maxCount);
        const childrenHidden = childrenWithProps.slice(maxCount, numOfChildren);
        childrenShow.push(React.createElement(Popover, { key: "avatar-popover-key", content: childrenHidden, trigger: maxPopoverTrigger, placement: maxPopoverPlacement, overlayClassName: `${prefixCls}-popover` },
            React.createElement(Avatar, { style: maxStyle }, `+${numOfChildren - maxCount}`)));
        return (React.createElement(SizeContextProvider, { size: size },
            React.createElement("div", { className: cls, style: props.style }, childrenShow)));
    }
    return (React.createElement(SizeContextProvider, { size: size },
        React.createElement("div", { className: cls, style: props.style }, childrenWithProps)));
};
export default Group;
