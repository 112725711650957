var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { ConfigContext } from '../config-provider';
import Tooltip from '../tooltip';
import { getRenderPropValue } from '../_util/getRenderPropValue';
import { getTransitionName } from '../_util/motion';
const Popover = React.forwardRef((_a, ref) => {
    var { prefixCls: customizePrefixCls, title, content, _overlay } = _a, otherProps = __rest(_a, ["prefixCls", "title", "content", "_overlay"]);
    const { getPrefixCls } = React.useContext(ConfigContext);
    const getOverlay = (prefixCls) => {
        if (!title && !content)
            return undefined;
        return (React.createElement(React.Fragment, null,
            title && React.createElement("div", { className: `${prefixCls}-title` }, getRenderPropValue(title)),
            React.createElement("div", { className: `${prefixCls}-inner-content` }, getRenderPropValue(content))));
    };
    const prefixCls = getPrefixCls('popover', customizePrefixCls);
    const rootPrefixCls = getPrefixCls();
    return (React.createElement(Tooltip, Object.assign({}, otherProps, { prefixCls: prefixCls, ref: ref, overlay: _overlay || getOverlay(prefixCls), transitionName: getTransitionName(rootPrefixCls, 'zoom-big', otherProps.transitionName) })));
});
if (process.env.NODE_ENV !== 'production') {
    Popover.displayName = 'Popover';
}
Popover.defaultProps = {
    placement: 'top',
    trigger: 'hover',
    mouseEnterDelay: 0.1,
    mouseLeaveDelay: 0.1,
    overlayStyle: {},
};
export default Popover;
