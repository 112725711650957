import React from 'react';
export const offset = 4;
export default function dropIndicatorRender(props) {
    const { dropPosition, dropLevelOffset, prefixCls, indent, direction = 'ltr' } = props;
    const startPosition = direction === 'ltr' ? 'left' : 'right';
    const endPosition = direction === 'ltr' ? 'right' : 'left';
    const style = {
        [startPosition]: -dropLevelOffset * indent + offset,
        [endPosition]: 0,
    };
    switch (dropPosition) {
        case -1:
            style.top = -3;
            break;
        case 1:
            style.bottom = -3;
            break;
        default:
            // dropPosition === 0
            style.bottom = -3;
            style[startPosition] = indent + offset;
            break;
    }
    return React.createElement("div", { style: style, className: `${prefixCls}-drop-indicator` });
}
