import classNames from 'classnames';
import * as React from 'react';
import { ConfigConsumer } from '../config-provider';
import defaultRenderEmpty from '../config-provider/defaultRenderEmpty';
import { FormItemInputContext } from '../form/context';
import LocaleReceiver from '../locale-provider/LocaleReceiver';
import defaultLocale from '../locale/default';
import { getMergedStatus, getStatusClassNames } from '../_util/statusUtils';
import warning from '../_util/warning';
import List from './list';
import Operation from './operation';
import Search from './search';
export { TransferListProps } from './list';
export { TransferOperationProps } from './operation';
export { TransferSearchProps } from './search';
class Transfer extends React.Component {
    constructor(props) {
        super(props);
        this.separatedDataSource = null;
        this.setStateKeys = (direction, keys) => {
            if (direction === 'left') {
                this.setState(({ sourceSelectedKeys }) => ({
                    sourceSelectedKeys: typeof keys === 'function' ? keys(sourceSelectedKeys || []) : keys,
                }));
            }
            else {
                this.setState(({ targetSelectedKeys }) => ({
                    targetSelectedKeys: typeof keys === 'function' ? keys(targetSelectedKeys || []) : keys,
                }));
            }
        };
        this.getLocale = (transferLocale, renderEmpty) => (Object.assign(Object.assign(Object.assign({}, transferLocale), { notFoundContent: renderEmpty('Transfer') }), this.props.locale));
        this.moveTo = (direction) => {
            const { targetKeys = [], dataSource = [], onChange } = this.props;
            const { sourceSelectedKeys, targetSelectedKeys } = this.state;
            const moveKeys = direction === 'right' ? sourceSelectedKeys : targetSelectedKeys;
            // filter the disabled options
            const newMoveKeys = moveKeys.filter((key) => !dataSource.some(data => !!(key === data.key && data.disabled)));
            // move items to target box
            const newTargetKeys = direction === 'right'
                ? newMoveKeys.concat(targetKeys)
                : targetKeys.filter(targetKey => newMoveKeys.indexOf(targetKey) === -1);
            // empty checked keys
            const oppositeDirection = direction === 'right' ? 'left' : 'right';
            this.setStateKeys(oppositeDirection, []);
            this.handleSelectChange(oppositeDirection, []);
            onChange === null || onChange === void 0 ? void 0 : onChange(newTargetKeys, direction, newMoveKeys);
        };
        this.moveToLeft = () => this.moveTo('left');
        this.moveToRight = () => this.moveTo('right');
        this.onItemSelectAll = (direction, selectedKeys, checkAll) => {
            this.setStateKeys(direction, prevKeys => {
                let mergedCheckedKeys = [];
                if (checkAll) {
                    // Merge current keys with origin key
                    mergedCheckedKeys = Array.from(new Set([...prevKeys, ...selectedKeys]));
                }
                else {
                    // Remove current keys from origin keys
                    mergedCheckedKeys = prevKeys.filter((key) => selectedKeys.indexOf(key) === -1);
                }
                this.handleSelectChange(direction, mergedCheckedKeys);
                return mergedCheckedKeys;
            });
        };
        this.onLeftItemSelectAll = (selectedKeys, checkAll) => this.onItemSelectAll('left', selectedKeys, checkAll);
        this.onRightItemSelectAll = (selectedKeys, checkAll) => this.onItemSelectAll('right', selectedKeys, checkAll);
        this.handleFilter = (direction, e) => {
            const { onSearch } = this.props;
            const { value } = e.target;
            onSearch === null || onSearch === void 0 ? void 0 : onSearch(direction, value);
        };
        this.handleLeftFilter = (e) => this.handleFilter('left', e);
        this.handleRightFilter = (e) => this.handleFilter('right', e);
        this.handleClear = (direction) => {
            const { onSearch } = this.props;
            onSearch === null || onSearch === void 0 ? void 0 : onSearch(direction, '');
        };
        this.handleLeftClear = () => this.handleClear('left');
        this.handleRightClear = () => this.handleClear('right');
        this.onItemSelect = (direction, selectedKey, checked) => {
            const { sourceSelectedKeys, targetSelectedKeys } = this.state;
            const holder = direction === 'left' ? [...sourceSelectedKeys] : [...targetSelectedKeys];
            const index = holder.indexOf(selectedKey);
            if (index > -1) {
                holder.splice(index, 1);
            }
            if (checked) {
                holder.push(selectedKey);
            }
            this.handleSelectChange(direction, holder);
            if (!this.props.selectedKeys) {
                this.setStateKeys(direction, holder);
            }
        };
        this.onLeftItemSelect = (selectedKey, checked) => this.onItemSelect('left', selectedKey, checked);
        this.onRightItemSelect = (selectedKey, checked) => this.onItemSelect('right', selectedKey, checked);
        this.onRightItemRemove = (selectedKeys) => {
            const { targetKeys = [], onChange } = this.props;
            this.setStateKeys('right', []);
            onChange === null || onChange === void 0 ? void 0 : onChange(targetKeys.filter(key => !selectedKeys.includes(key)), 'left', [...selectedKeys]);
        };
        this.handleScroll = (direction, e) => {
            const { onScroll } = this.props;
            onScroll === null || onScroll === void 0 ? void 0 : onScroll(direction, e);
        };
        this.handleLeftScroll = (e) => this.handleScroll('left', e);
        this.handleRightScroll = (e) => this.handleScroll('right', e);
        // eslint-disable-next-line class-methods-use-this
        this.handleListStyle = (listStyle, direction) => {
            if (typeof listStyle === 'function') {
                return listStyle({ direction });
            }
            return listStyle;
        };
        this.renderTransfer = (transferLocale) => (React.createElement(ConfigConsumer, null, ({ getPrefixCls, renderEmpty, direction }) => (React.createElement(FormItemInputContext.Consumer, null, ({ hasFeedback, status: contextStatus }) => {
            const { prefixCls: customizePrefixCls, className, disabled, operations = [], showSearch, footer, style, listStyle, operationStyle, filterOption, render, children, showSelectAll, oneWay, pagination, status: customStatus, } = this.props;
            const prefixCls = getPrefixCls('transfer', customizePrefixCls);
            const locale = this.getLocale(transferLocale, renderEmpty || defaultRenderEmpty);
            const { sourceSelectedKeys, targetSelectedKeys } = this.state;
            const mergedStatus = getMergedStatus(contextStatus, customStatus);
            const mergedPagination = !children && pagination;
            const { leftDataSource, rightDataSource } = this.separateDataSource();
            const leftActive = targetSelectedKeys.length > 0;
            const rightActive = sourceSelectedKeys.length > 0;
            const cls = classNames(prefixCls, {
                [`${prefixCls}-disabled`]: disabled,
                [`${prefixCls}-customize-list`]: !!children,
                [`${prefixCls}-rtl`]: direction === 'rtl',
            }, getStatusClassNames(prefixCls, mergedStatus, hasFeedback), className);
            const titles = this.getTitles(locale);
            const selectAllLabels = this.props.selectAllLabels || [];
            return (React.createElement("div", { className: cls, style: style },
                React.createElement(List, Object.assign({ prefixCls: `${prefixCls}-list`, titleText: titles[0], dataSource: leftDataSource, filterOption: filterOption, style: this.handleListStyle(listStyle, 'left'), checkedKeys: sourceSelectedKeys, handleFilter: this.handleLeftFilter, handleClear: this.handleLeftClear, onItemSelect: this.onLeftItemSelect, onItemSelectAll: this.onLeftItemSelectAll, render: render, showSearch: showSearch, renderList: children, footer: footer, onScroll: this.handleLeftScroll, disabled: disabled, direction: direction === 'rtl' ? 'right' : 'left', showSelectAll: showSelectAll, selectAllLabel: selectAllLabels[0], pagination: mergedPagination }, locale)),
                React.createElement(Operation, { className: `${prefixCls}-operation`, rightActive: rightActive, rightArrowText: operations[0], moveToRight: this.moveToRight, leftActive: leftActive, leftArrowText: operations[1], moveToLeft: this.moveToLeft, style: operationStyle, disabled: disabled, direction: direction, oneWay: oneWay }),
                React.createElement(List, Object.assign({ prefixCls: `${prefixCls}-list`, titleText: titles[1], dataSource: rightDataSource, filterOption: filterOption, style: this.handleListStyle(listStyle, 'right'), checkedKeys: targetSelectedKeys, handleFilter: this.handleRightFilter, handleClear: this.handleRightClear, onItemSelect: this.onRightItemSelect, onItemSelectAll: this.onRightItemSelectAll, onItemRemove: this.onRightItemRemove, render: render, showSearch: showSearch, renderList: children, footer: footer, onScroll: this.handleRightScroll, disabled: disabled, direction: direction === 'rtl' ? 'left' : 'right', showSelectAll: showSelectAll, selectAllLabel: selectAllLabels[1], showRemove: oneWay, pagination: mergedPagination }, locale))));
        }))));
        const { selectedKeys = [], targetKeys = [] } = props;
        this.state = {
            sourceSelectedKeys: selectedKeys.filter(key => targetKeys.indexOf(key) === -1),
            targetSelectedKeys: selectedKeys.filter(key => targetKeys.indexOf(key) > -1),
        };
    }
    static getDerivedStateFromProps({ selectedKeys, targetKeys, pagination, children, }) {
        if (selectedKeys) {
            const mergedTargetKeys = targetKeys || [];
            return {
                sourceSelectedKeys: selectedKeys.filter(key => !mergedTargetKeys.includes(key)),
                targetSelectedKeys: selectedKeys.filter(key => mergedTargetKeys.includes(key)),
            };
        }
        warning(!pagination || !children, 'Transfer', '`pagination` not support customize render list.');
        return null;
    }
    getTitles(transferLocale) {
        var _a;
        return (_a = this.props.titles) !== null && _a !== void 0 ? _a : transferLocale.titles;
    }
    handleSelectChange(direction, holder) {
        const { sourceSelectedKeys, targetSelectedKeys } = this.state;
        const { onSelectChange } = this.props;
        if (!onSelectChange) {
            return;
        }
        if (direction === 'left') {
            onSelectChange(holder, targetSelectedKeys);
        }
        else {
            onSelectChange(sourceSelectedKeys, holder);
        }
    }
    separateDataSource() {
        const { dataSource, rowKey, targetKeys = [] } = this.props;
        const leftDataSource = [];
        const rightDataSource = new Array(targetKeys.length);
        dataSource.forEach((record) => {
            if (rowKey) {
                record = Object.assign(Object.assign({}, record), { key: rowKey(record) });
            }
            // rightDataSource should be ordered by targetKeys
            // leftDataSource should be ordered by dataSource
            const indexOfKey = targetKeys.indexOf(record.key);
            if (indexOfKey !== -1) {
                rightDataSource[indexOfKey] = record;
            }
            else {
                leftDataSource.push(record);
            }
        });
        return {
            leftDataSource,
            rightDataSource,
        };
    }
    render() {
        return (React.createElement(LocaleReceiver, { componentName: "Transfer", defaultLocale: defaultLocale.Transfer }, this.renderTransfer));
    }
}
// For high-level customized Transfer @dqaria
Transfer.List = List;
Transfer.Operation = Operation;
Transfer.Search = Search;
Transfer.defaultProps = {
    dataSource: [],
    locale: {},
    showSearch: false,
    listStyle: () => { },
};
export default Transfer;
