import * as React from 'react';
import { cloneElement } from '../_util/reactNode';
import Statistic from './Statistic';
import { formatCountdown } from './utils';
const REFRESH_INTERVAL = 1000 / 30;
function getTime(value) {
    return new Date(value).getTime();
}
class Countdown extends React.Component {
    constructor() {
        super(...arguments);
        this.syncTimer = () => {
            const { value } = this.props;
            const timestamp = getTime(value);
            if (timestamp >= Date.now()) {
                this.startTimer();
            }
            else {
                this.stopTimer();
            }
        };
        this.startTimer = () => {
            if (this.countdownId)
                return;
            const { onChange, value } = this.props;
            const timestamp = getTime(value);
            this.countdownId = window.setInterval(() => {
                this.forceUpdate();
                if (onChange && timestamp > Date.now()) {
                    onChange(timestamp - Date.now());
                }
            }, REFRESH_INTERVAL);
        };
        this.stopTimer = () => {
            const { onFinish, value } = this.props;
            if (this.countdownId) {
                clearInterval(this.countdownId);
                this.countdownId = undefined;
                const timestamp = getTime(value);
                if (onFinish && timestamp < Date.now()) {
                    onFinish();
                }
            }
        };
        this.formatCountdown = (value, config) => {
            const { format } = this.props;
            return formatCountdown(value, Object.assign(Object.assign({}, config), { format }));
        };
        // Countdown do not need display the timestamp
        // eslint-disable-next-line class-methods-use-this
        this.valueRender = (node) => cloneElement(node, {
            title: undefined,
        });
    }
    componentDidMount() {
        this.syncTimer();
    }
    componentDidUpdate() {
        this.syncTimer();
    }
    componentWillUnmount() {
        this.stopTimer();
    }
    render() {
        return (React.createElement(Statistic, Object.assign({ valueRender: this.valueRender }, this.props, { formatter: this.formatCountdown })));
    }
}
Countdown.defaultProps = {
    format: 'HH:mm:ss',
};
export default Countdown;
