import InternalCard from './Card';
import Grid from './Grid';
import Meta from './Meta';
export { CardProps, CardTabListType } from './Card';
export { CardGridProps } from './Grid';
export { CardMetaProps } from './Meta';
const Card = InternalCard;
Card.Grid = Grid;
Card.Meta = Meta;
export default Card;
