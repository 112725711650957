import memoizeOne from 'memoize-one';
import * as React from 'react';
import warning from '../_util/warning';
import { changeConfirmLocale } from '../modal/locale';
import LocaleContext from './context';
export const ANT_MARK = 'internalMark';
export default class LocaleProvider extends React.Component {
    constructor(props) {
        super(props);
        this.getMemoizedContextValue = memoizeOne((localeValue) => (Object.assign(Object.assign({}, localeValue), { exist: true })));
        changeConfirmLocale(props.locale && props.locale.Modal);
        warning(props._ANT_MARK__ === ANT_MARK, 'LocaleProvider', '`LocaleProvider` is deprecated. Please use `locale` with `ConfigProvider` instead: http://u.ant.design/locale');
    }
    componentDidMount() {
        changeConfirmLocale(this.props.locale && this.props.locale.Modal);
    }
    componentDidUpdate(prevProps) {
        const { locale } = this.props;
        if (prevProps.locale !== locale) {
            changeConfirmLocale(locale && locale.Modal);
        }
    }
    componentWillUnmount() {
        changeConfirmLocale();
    }
    render() {
        const { locale, children } = this.props;
        const contextValue = this.getMemoizedContextValue(locale);
        return React.createElement(LocaleContext.Provider, { value: contextValue }, children);
    }
}
LocaleProvider.defaultProps = {
    locale: {},
};
