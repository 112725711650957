import DotChartOutlined from '@ant-design/icons/DotChartOutlined';
import classNames from 'classnames';
import * as React from 'react';
import { ConfigContext } from '../config-provider';
const SkeletonNode = props => {
    var _a;
    const { prefixCls: customizePrefixCls, className, style, active } = props;
    const { getPrefixCls } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('skeleton', customizePrefixCls);
    const cls = classNames(prefixCls, `${prefixCls}-element`, {
        [`${prefixCls}-active`]: active,
    }, className);
    const content = (_a = props.children) !== null && _a !== void 0 ? _a : React.createElement(DotChartOutlined, null);
    return (React.createElement("div", { className: cls },
        React.createElement("div", { className: classNames(`${prefixCls}-image`, className), style: style }, content)));
};
export default SkeletonNode;
