// eslint-disable-next-line import/prefer-default-export
export function easeInOutCubic(t, b, c, d) {
    const cc = c - b;
    t /= d / 2;
    if (t < 1) {
        return (cc / 2) * t * t * t + b;
    }
    // eslint-disable-next-line no-return-assign
    return (cc / 2) * ((t -= 2) * t * t + 2) + b;
}
